import { ChiefComplaintSurvey } from '@chiroup/components';
import { useContext } from 'react';
import { MeContext } from '../../contexts/me.context';
import requestService from '../../services/request.service';
import { Genders } from '@chiroup/core/enums/Genders.enum';
import { RequestStep } from '@chiroup/core/types/Request.type';
import { SurveyQuestion } from '@chiroup/core/types/Survey.type';

type Props = {
  age: number;
  ccsFollowups?: string[];
  complete: () => void;
  gender: Genders;
  id: string;
  kiosk: boolean;
  language: 'en' | 'es' | undefined;
  startQuestion: SurveyQuestion;
  surveyId: string;
  surveyIds?: string[];
  markSurveyComplete: () => void;
  updateRequest?: (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => void;
  validationCode: string | number;
  prMap: { [key: string]: string };
  encounterId?: string;
  additionalComplaints?: boolean;
  abbreviatedCCS?: boolean;
};

const InterCCS: React.FC<Props> = ({
  age,
  ccsFollowups,
  complete,
  gender,
  id,
  language,
  startQuestion,
  surveyId,
  surveyIds,
  updateRequest,
  validationCode,
  prMap,
  markSurveyComplete,
  kiosk,
  encounterId,
  additionalComplaints = false,
  abbreviatedCCS = false,
}: Props) => {
  const {
    clinicInfo: { primaryColor, doNotAllowUnrelatedRegions },
  } = useContext(MeContext);

  const submit = (
    questions: SurveyQuestion[],
    kiosk: boolean,
    spanishQuestions?: SurveyQuestion[],
    ccsFollowupSurveyIds?: string[],
    validationCode?: string | number,
  ) => {
    return requestService.submitSurvey(
      id,
      surveyId,
      questions,
      kiosk,
      prMap,
      spanishQuestions,
      ccsFollowupSurveyIds,
      validationCode,
      encounterId,
    );
  };

  return (
    <ChiefComplaintSurvey
      language={language}
      gender={gender}
      age={age}
      primaryColor={primaryColor}
      startQuestion={startQuestion}
      complete={complete}
      ccsFollowups={ccsFollowups}
      markSurveyComplete={markSurveyComplete}
      updateRequest={updateRequest}
      surveyIds={surveyIds}
      validationCode={validationCode}
      prMap={prMap}
      submitSurvey={submit}
      kiosk={kiosk}
      additionalComplaints={additionalComplaints}
      abbreviatedCCS={abbreviatedCCS}
      doNotAllowUnrelatedRegions={doNotAllowUnrelatedRegions}
    />
  );
};

export default InterCCS;
